
/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


body{
  background-color: #001f3b;
  color: rgb(209, 139, 8);
  overflow-x: hidden;
  
}
body a{
  color: white;
  cursor: pointer;
}